import aiDataService from "services/AIDataService";
import { TOPIC_ENGINE, OUTLINE_ENGINE, SCENE_ENGINE } from "./helper";

export const getTopicFromKeyword = (topicData) => {
  return new Promise((resolve, reject) => {
    var data = JSON.stringify({
      keyword: topicData.videoTitle,
      lang :topicData.lang,
      // engine: TOPIC_ENGINE,
    });

    aiDataService
      .getTopicsFromKeyword(data)
      .then((response) => {
        const topic = response.success.split("\n").filter(function (el) {
          return el !== "";
        });
        resolve(topic);
      })
      .catch(() => {
        reject();
      });
  });
};

export const getOutlineFromKeyword = (topicData) => {
  return new Promise((resolve, reject) => {
    var data = {
      keyword: topicData.videoTitle,
      // engine: OUTLINE_ENGINE,
    };

    aiDataService
      .getOutline(data)
      .then((response) => {
        if (response && response.success) {
          resolve(response.success);
        } else {
          reject('Invalid response');
        }
      })
      .catch(() => {
        reject();
      });
  });
}


export const getChatOutline = (topicData) => {
  return new Promise((resolve, reject) => {
    var data = {
      keyword: topicData.videoTitle,
      lang :topicData.lang,
      // engine: OUTLINE_ENGINE,
    };

    aiDataService
      .getOutline(data)
      .then((response) => {
        if (response && response.success) {
          resolve(response.success);
        } else {
          reject('Invalid response');
        }
      })
      .catch(() => {
        reject();
      });
  });
}

export const generateSceneWithMedia = (sceneData) => {
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({ scenes: sceneData });
    aiDataService.getMedia(data).then((response)=>{
      resolve(response.success)
    }).catch(()=>{
      reject()
    })
  })

};

export const generateSceneWithMediaURLMode = (sceneData) => {
  return new Promise((resolve, reject) => {
    const data = JSON.stringify({ scenes: sceneData });
    aiDataService.getMediaFromUrlMode(data).then((response)=>{
      resolve(response.success)
    }).catch(()=>{
      reject()
    })
  })

};

export const getSlideContent =(topic)=>{
  return new Promise((resolve, reject) => {
    var data = JSON.stringify({
      topic: topic.trim()
      // engine: SCENE_ENGINE,
      // num_slides: 10
    });
    aiDataService.getSlideContent(data).then((response)=>{
      resolve(response.success)
    }).catch(()=>{
      reject()
    })
  })
}
