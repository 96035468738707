import urlContentService from "services/URLContentService";
import { genetateRestoryId } from "./helper";

import { notification } from "antd";

let notificationParam = {
  message: "",
};
export const getChunkFromUrl = (data) => {
  return new Promise((resolve, reject) => {
    urlContentService
      .getChunks(JSON.stringify({ url: data.url }))
      .then((response) => {
        resolve(response);
      })
      .catch(() => {
        notificationParam.message = "Something went wrong";
        notification.error(notificationParam);
        reject();
      });
  });
};

export const getAISummary = (data) => {
  return new Promise((resolve, reject) => {
    const temp = { text: data.text, sum_id: genetateRestoryId() };
    urlContentService
      .getAzSummary(temp)
      .then((response) => {
        resolve(response);
      })
      .catch(() => {
        notificationParam.message = "Something went wrong";
        notification.error(notificationParam);
        reject();
      });
  });
};

export const getSummarizeTextResult = (data) => {
  return new Promise((resolve, reject) => {
    urlContentService
      .getSummarizeTextResult(data)
      .then((response) => {
        resolve(response);
      })
      .catch(() => {
        notificationParam.message = "Something went wrong";
        notification.error(notificationParam);
        reject();
      });
  });
}
