import fetch from "auth/FetchInterceptor";
const aiDataService = {};

aiDataService.getTopicsFromKeyword = function (data) {
  return fetch({
    url: '/ai_topic',
    method: 'post',
    data: data
  })
}

aiDataService.getOutline = function (data) {
  return fetch({
    url: '/ai_outline',
    method: 'post',
    data: data
  })
}


aiDataService.getMedia = function (data) {
  return fetch({
    url: '/ai_media_from_sentence',
    method: 'post',
    data: data
  })
}

aiDataService.getMediaFromUrlMode = function (data) {
  return fetch({
    url: '/ai_media_from_url',
    method: 'post',
    data: data
  })
}





aiDataService.getSlideContent = function (data) {
  return fetch({
    url: '/ai_slide_content',
    method: 'post',
    data: data
  })
}

aiDataService.getChatbotSlideContent = function (data) {
  return fetch({
    url: '/chatbot_ai_content',
    method: 'post',
    data: data
  })
}


export default aiDataService;
