import { getOutlineFromKeyword, getTopicFromKeyword, getChatOutline } from "utils/common/aiDataHelper";
import useUserData from 'utils/hooks/useUserData';
import { createProject } from "utils/common/projectHelper";
import { getChunkFromUrl } from "utils/common/urlDataHelper";
import { Navigate } from "react-router-dom";

export const apiCallsForScenarios = async (apiname, apiData) => {
    let data = {}
    switch (apiname) {
        case 'ideaAPI':
            data = {
                videoTitle: apiData.payload,
                lang : apiData.lang
            }

            const response = await getTopicFromKeyword(data);
            const respai = {
                response: response
            }
            return respai;


        case 'outlineAPI':
            data = {
                videoTitle: apiData.payload,
                lang : apiData.lang
            }
            const aiproject = await createProject({userData:apiData.userData, source:'AI'})
            
            try {
                const outLineResp = await getChatOutline(data);
                // console.log("Outline Response:", outLineResp);  // Logging the response
        
                const respoutline = {
                    project: aiproject,
                    response: outLineResp
                }
                return respoutline;
            } catch(error) {
                console.error("Error getting outline:", error);
                throw error; // If you want the error to propagate
            }
            
       
        case 'urlAPI':
            const project = await createProject({ userData: apiData.userData, source: 'URL' })
            const chunk = await getChunkFromUrl({ url: apiData.payload })
            const resp = {
                project: project,
                response: chunk
            }
            return resp;
        case 'scratchAPI':
            // console.log(apiData)
            const scratchResp = await createProject({ userData: apiData.userData, source: 'SCRATCH', projectName:apiData.payload })
            const ScratchResp = {
                project: scratchResp,
                response: scratchResp
            }
            return ScratchResp;

        default:
            break;
    }
}

