import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'


const lazyRetry = function(componentImport) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
        );
        // try to import the component
        componentImport().then((component) => {
            window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) { // not been refreshed yet
                window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh
        });
    });
};

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'reset-password',
        path: `reset-password`,
        component: React.lazy(() =>import('views/auth-views/authentication/reset-password')),
    },
    {
        key: 'impersonate',
        path: `${AUTH_PREFIX_PATH}/impersonate`,
        component: React.lazy(() =>import('views/auth-views/authentication/impersonate')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'campaigns',
        path: `${APP_PREFIX_PATH}/campaigns`,
        component: React.lazy(() => lazyRetry(() => import('views/app-views/campaigns'))),
    },
    
    {
        key: 'templates',
        path: `${APP_PREFIX_PATH}/templates`,
        component: React.lazy(() => lazyRetry(() => import('views/app-views/templates'))),
    },
    {
        key: 'bonuses',
        path: `${APP_PREFIX_PATH}/bonuses`,
        component: React.lazy(() => lazyRetry(() => import('views/app-views/bonuses'))),
    },

    {
        key: 'profile',
        path: `${APP_PREFIX_PATH}/profile`,
        component: React.lazy(() => import('views/app-views/profile')),
    },
    {
        key: 'password',
        path: `${APP_PREFIX_PATH}/change-password`,
        component: React.lazy(() => import('views/app-views/password')),
    },
    {
        key: 'privacy',
        path: `${APP_PREFIX_PATH}/privacy`,
        component: React.lazy(() => import('views/app-views/privacy')),
    },
    {
        key: 'generate',
        path: `${APP_PREFIX_PATH}/generate`,
        component: React.lazy(() => import('views/app-views/generate')),
    },
    {
        key: 'studio',
        path: `${APP_PREFIX_PATH}/studio`,
        component: React.lazy(() => lazyRetry(() => import('views/app-views/studio'))),
    },
    {
        key:'tutorials',
        path:`${APP_PREFIX_PATH}/tutorials`,
        component: React.lazy(() => import('views/app-views/tutorials')),
    },
    {
        key:'agency',
        path:`${APP_PREFIX_PATH}/agency`,
        component: React.lazy(() => import('views/app-views/agency')),
    },
    {
        key:'teams',
        path:`${APP_PREFIX_PATH}/teams`,
        component: React.lazy(() => import('views/app-views/teams')),
    },
    {
        key: 'whitelabelUser',
        path: `whitelabel/users`,
        component: React.lazy(() =>import('views/app-views/whitelabel')),

    },
    {
        key: 'whitelabelSettings',
        path: `whitelabel/settings`,
        component : React.lazy(() =>import('views/app-views/whitelabel/Settings'))
    }
     ,

    {
        key: 'purchase',
        path: `${APP_PREFIX_PATH}/purchase`,
        component: React.lazy(() => import('views/app-views/purchase'))
    },
    {
        key: 'chatbot',
        path: `${APP_PREFIX_PATH}/chatbot`,
        component: React.lazy(() => import('views/app-views/chatbot'))
    },
    {
        key: 'generating',
        path: `${APP_PREFIX_PATH}/generating`,
        component: React.lazy(() => import('views/app-views/generating'))
    },
    {
        key:'master',
        path:`${APP_PREFIX_PATH}/master`,
        component: React.lazy(() => import('views/app-views/master')),
    },
    {
        key:'master',
        path:`${APP_PREFIX_PATH}/master`,
        component: React.lazy(() => import('views/app-views/master')),
    },
    {
        key:'transactions',
        path:`${APP_PREFIX_PATH}/master/transactions`,
        component: React.lazy(() => import('views/app-views/master/Transactions')),
    },
    {
        key:'jvzoo',
        path:`${APP_PREFIX_PATH}/master/jvzoo`,
        component: React.lazy(() => import('views/app-views/master/JvzooTransaction')),
    },

]