import React, { useState } from "react";
import {
  Menu,
  Dropdown,
  Typography,
  Button,
  Modal,
  Row,
  Col,
  Space,
  Tooltip, Switch
} from "antd";
import {
  YoutubeOutlined,
  InstagramOutlined,
  FacebookOutlined,
} from "@ant-design/icons";
import { QuestionCircleOutlined } from "@ant-design/icons";
import ChatBox from "views/app-views/generate";
import { ChooseSource } from "views/app-views/campaigns/components/ChooseSource";


const { Title } = Typography;

export const CreateVideo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAIMode, setIsAIMode] = useState(true);


  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="nav-item">
      <div className="d-flex align-items-center">
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={showModal}
          >
            Create New Video
          </Button>
          {/* <Tooltip title="Support">
            <Button
              size="small"
              type="Link"
              target="_blank"
              href="https://aivideobuilder.com/support"
              icon={<QuestionCircleOutlined />}
            ></Button>
          </Tooltip> */}
        </Space>

        <Modal
          width={'70vw'}
          centered
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          destroyOnClose={true}
          footer={null}
          className="gradient-modal"
          
        >
          <Row justify="center" style={{ marginBottom: 20, position: 'sticky', top: 0,  zIndex: 10 }}>
            <Col>
              <span>Manual Mode</span>
              <Switch
                size="large"
                checked={isAIMode}
                onChange={checked => setIsAIMode(checked)}
                style={{ margin: '0 10px' }}
              />
              <span>AI Mode</span>
            </Col>
          </Row>

          <div style={{ overflowY: 'auto', flexGrow: 1 }}>  {/* Add this div */}
            {isAIMode ? <ChatBox setModelOpen={setIsModalOpen} /> : <ChooseSource isAIModalOpen={isModalOpen} setAIModaClose={setIsModalOpen} />}
          </div>
        </Modal>

      </div>
    </div>
  );
};

export default CreateVideo;
