import React, { useEffect, useState } from "react";
import { getAllTemplates } from "@googleforcreators/templates";
import { Row, Col, Card, Modal, Typography, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { createProject } from "utils/common/projectHelper";
import { Spin } from 'antd';
import useUserData from "utils/hooks/useUserData";
import { LoadingOutlined } from '@ant-design/icons';
import { INITIAL_EDITS, UNLIMITED_PERMISSION, generateRandomString } from "utils/common/helper";
import { notification } from "antd";
import {
  StarOutlined
} from '@ant-design/icons';

import Plyr from 'plyr-react';

import "./../dashboards/default/plyr.css"
import "./../dashboards/default/style.css"
import { createCampaign } from "utils/api/campaign";

const cdnURL = "https://wp.stories.google/static/main/";
const { Meta } = Card;
const { Title } = Typography;
const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />
let notificationParam = {
  message: "",
};
const initialEdits = {
  title: {
    raw: "",
  },
  storyData: {
    version: 1,
    pages: [],
    currentStoryStyles: {
      colors: [],
    },
    stylePresets: {
      colors: [],
      textStyles: [],
    },
    permalinkTemplate: "https://example.org/web-stories/%pagename%/",
  },
};

const proStyle = {
  border: '3px solid rgba(187, 188, 71, 0.77)',
  cursor: "zoom-in"
};
const style = {
  cursor: "zoom-in"
};

export const TemplateList = ({ userData }) => {
  const planId = JSON.parse(window.localStorage.getItem("plan_id"));
  const [hasProPermission] = useState(planId?.some(item => UNLIMITED_PERMISSION.includes(item)))
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTemplateBtnLoading, setTemplateBtnLoading] = useState(false);
  const [templates, setTemplates] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const showModal = (template) => {
    setIsModalOpen(true);
    setSelectedTemplate(template);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleUseTemplateClick = () => {
      INITIAL_EDITS.title = selectedTemplate.title;
    INITIAL_EDITS.storyData.pages = selectedTemplate.pages;
    const values ={
      input_data : INITIAL_EDITS,
      project_name : generateRandomString(6),

    }
    setTemplateBtnLoading(true);
    createCampaign(values).then((response) => {
      setTemplateBtnLoading(false);
      navigate(`/studio?template=${selectedTemplate.id}&projectId=${response.project_id}`, {
        state: initialEdits,
      });
    })


  };


  useEffect(() => {
    getAllTemplates({ cdnURL }).then((response) => {
      
      if (hasProPermission) {
        const templates = response.reverse();
        setTemplates(templates);
      }
      else {
        const templates = response.filter((data) => {
          return !data.permission
        }).reverse();
        setTemplates(templates);
      }


    });
  }, []);


  return (
    <>
      {templates && (
        <div>
          <Row gutter={20}>
            {templates.map((element) => (
              <Col span={6}>
                <Card
                  style={element.permission ? proStyle : style}
                  onClick={() => {
                    showModal(element);
                  }}
                  cover={
                    <img alt="preview" style={element.permission ? { border: '1px solid rgba(187, 188, 71, 0.77)' } : null} src={element.postersByPage[0]?.png} />
                  }
                >
                  <Meta title={element.title} />
                  {element.permission ? `PRO` : null}
                </Card>
              </Col>
            ))}
          </Row>

          <Modal
            centered
            width={"90%"}
            open={isModalOpen}
            onOk={handleOk}
            destroyOnClose
            onCancel={handleCancel}
            footer={null}
            maskStyle={{ background: "rgba(46,46,56,0.95)" }}
          >
            <Row align="middle" gutter={40}>
              <Col span={14}>

                <Plyr
                  source={
                    {
                      type: 'video',
                      sources: [
                        {
                          src: selectedTemplate?.preview,
                          provider: 'html5',
                        },
                      ],
                    }
                  }

                  options={{
                    controls: [
                      "rewind",
                      "play",
                      "fast-forward",
                      "progress",
                      "current-time",
                      "duration",
                      "mute",
                      "volume",
                      "settings",
                      "fullscreen"
                    ]
                  }}
                />
                <Row gutter={25}>

                  {selectedTemplate?.postersByPage.map((element) => (
                    <Col className="mt-4" span={4} >
                      <img
                        style={{ width: "100%", borderRadius: "6px" }}
                        alt="preview"
                        src={element.png}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col span={10}>
                <h2 level={1}>{selectedTemplate?.title}</h2>




                {/* <video src={selectedTemplate?.preview} /> */}
                <p level={5}>{selectedTemplate?.description}</p>
                {userData && <Button
                  className="mt-3"
                  size="large"
                  type="primary"
                  onClick={handleUseTemplateClick}
                  loading={isTemplateBtnLoading}
                >
                  Use This Template
                </Button>}

              </Col>
            </Row>
          </Modal>
        </div>
      )}
    </>
  );
};

export default TemplateList;
