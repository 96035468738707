export const TOPIC_ENGINE = "text-davinci-003";
export const PROJECT_CALL_INTERVAL = 50000
export const MAX_PROJECT_CALL = 10
export const MIN_DURATION = 2000;
export const MAX_DURATION = 60000;
export const OUTLINE_ENGINE = "text-davinci-003";
export const SCENE_ENGINE = "text-davinci-003";
export const SERVE_URL = "https://remotionlambda-useast1-obwsbnzwg5.s3.us-east-1.amazonaws.com/sites/aivb/index.html";
// "https://remotionlambda-tpfo0tmdhl.s3.us-east-1.amazonaws.com/sites/gpt-videos/index.html";
export const FUNCTION = "remotion-render-4-0-41-mem2048mb-disk2048mb-120sec";
//  "remotion-render-4-0-41-mem2048mb-disk2048mb-120sec";
export const WEBOOK_URL = "https://api.aivideobuilder.com/api/v1/re_renders_catch";
export const WL_PERMISSION = ['bundle','agency-25','agency-100']
export const WL_100 = ['reseller-100', 'bundle']
export const WEBSTORIES_PERMISSION = ['webstories']
export const UNLIMITED_PERMISSION = ['pro', 'pro-lite', 'bundle']
export const FPS = 30

export const INITIAL_EDITS = {
  title: {
    raw: "",
  },
  storyData: {
    version: 46,
    pages: [],
    currentStoryStyles: {
      colors: [],
    },
  },
  stylePresets: {
    colors: [],
    textStyles: [],
  },
  permalinkTemplate: "https://example.org/web-stories/%pagename%/",
};

export const PROJECT_JSON = {
  project_id: "",
  project_name: "",
  template_id: "Template01",
  orientation: "Wide",
  image_data: "",
  lang: "",
  brand_id: "832",
  user_id: "1169",
  source: "combined",
  scene_order: [],
  scene_data: null,
  input_data: {},
  project_type: 'AIVB',
  status: 'draft'
};

export const genetateRestoryId = () => {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

export const nameGenerator = (num) => {
  let res = "";
  for (let i = 0; i < num; i++) {
    const random = Math.floor(Math.random() * 27);
    res += String.fromCharCode(97 + random);
  }
  return `${res}`;
};

export const generateRandomString = (length) => {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};



export const calculatePageDuration = (page) => {
  var totalDuration = 0;
  if (page.animations === undefined) {
    return 2.0;
  }
  page?.animations.forEach((animation) => {
    var duration = animation.duration + animation.delay;
    if (duration > totalDuration) {
      if (duration > MIN_DURATION && duration <= MAX_DURATION) {
        totalDuration = duration;
      } else if (duration > MAX_DURATION) {
        totalDuration = MAX_DURATION;
      } else {
        totalDuration = MIN_DURATION;
      }
    }
  });
  return totalDuration ? totalDuration / 1000 : MIN_DURATION / 1000;
};

export const getSpanData = (templateContent, content) => {
  if (templateContent.indexOf(">") !== -1) {
    let index = templateContent.indexOf(">");
    let spanData = templateContent.substring(0, index + 1)
    return `${spanData}${content}</span>`
  }
  return content
}

export const getPoster = (video) => {
  var poster = video.poster
  if (video.picture_id) {
    poster = `https://i.vimeocdn.com/video/${video.picture_id}_640x360.jpg`
  }
  return poster
}

export const getDurationWithPolly = (page, polly) => {
  if (page > polly) {
    return page
  }
  else {
    return polly
  }
}

export const generateAWSSSML = (text, style) => {
  if (style === undefined) {
    return `<speak>${text}</speak>`
  }
  let ssml = ""
  switch (style['name']) {
    case 'soft':
      ssml = `<speak><amazon:effect name="soft">${text}</amazon:effect></speak>`
      break;
    case 'whispered':
      ssml = `<speak><amazon:effect name="whispered">${text}</amazon:effect></speak>`
      break;
    case 'breaths':
      ssml = `<speak><amazon:effect name="breath">${text}</amazon:effect></speak>`
      break;
    case 'drc':
      ssml = `<speak><amazon:effect name="drc">${text}</amazon:effect></speak>`
      break;
    case 'news':
      ssml = `<speak><amazon:domain name="news">${text}</amazon:domain></speak>`
      break;
    case 'conversational':
      ssml = `<speak><amazon:domain name="conversational">${text}</amazon:domain></speak>`
      break;
    case 'x-slow':
      ssml = `<speak><prosody rate="x-slow">${text}</prosody></speak>`
      break;
    case 'x-loud':
      ssml = `<speak><prosody pitch="x-high">${text}</prosody></speak>`
      break;
    default:
      ssml = `<speak>${text}</speak>`
      break;
  }


  return ssml
}


export const NeuralVoiceIds = ['Zhiyu', 'Laura', 'Olivia', 'Kajal', 'Amy', 'Brian', 'Emma', 'Arthur', 'Salli', 'Joanna', 'Kevin', 'Matthew', 'Ivy', 'Justin', 'Kendra', 'Kimberly', 'Joey', 'Ruth', 'Stephen', 'Gabrielle', 'Liam', 'Lea', 'Remi', 'Vicki', 'Daniel', 'Kajal', 'Bianca', 'Adriano', 'Takumi', 'Kazuha', 'Tomoko', 'Seoyeon', 'Ida', 'Ola', 'Vitoria', 'Camila', 'Thiago', 'Ines', 'Mia', 'Andres', 'Lucia', 'Sergio', 'Lupe', 'Pedro', 'Elin']

export const generateSSML = (text, selectedSpeaker, selectedStyle) => {
  if(text === undefined){
    return null
  }
  if(!selectedSpeaker){
    return `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang="en-US"><voice name="${selectedSpeaker.ShortName}">${text} </voice></speak>`
  }
  return `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang="en-US"><voice name="${selectedSpeaker.ShortName}"> <mstts:express-as style="${selectedStyle}" styledegree="2">${text}    </mstts:express-as></voice></speak>`
}