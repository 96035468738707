import React from 'react';
import { Layout, Typography } from 'antd';
import { AISource, ScratchSource, TemplateSource, URLSource } from 'assets/svg/icon';

const { Title } = Typography;

export const AICard = ({onClick}) => {
	return (
		<div className='source_item aisource' onClick={onClick}>
			<div className='icon mt-3'><AISource /></div>
			<Title className='title mt-4' level={5}>AI</Title>
		</div>
	);
}

export const URLCard = ({onClick}) => {
	return (
		<div className='source_item urlsource' onClick={onClick}>
			<div className='icon mt-3'><URLSource /></div>
			<Title className='title mt-4' level={5}>URL</Title>
		</div>
	);
}


export const TemplateCard = ({onClick}) => {
	return (
		<div className='source_item templatesource' onClick={onClick}>
		<div className='icon mt-3'><TemplateSource /></div>
			<Title className='title mt-4' level={5}>Templates</Title>
		</div>
	);
}


export const ScratchCard = ({onClick}) => {
	return (
		<div className='source_item scratchsource' onClick={onClick}>
		<div className='icon mt-3'><ScratchSource /></div>
			<Title className='title mt-4' level={5}>Scratch</Title>
		</div>
	);
}

export const IdeaCards = ({onClick,data}) => {
	return (
		<>
	
		<div className='idea_items' onClick={onClick}>
		<Title className='title mt-3 text-left' level={5}>{data}</Title>
		</div>
		</>
	);
}

